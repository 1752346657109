<template>
  <div class="align-self-center">
    <v-dialog
        v-model="dialogShow"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeDialog"
        transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar
            class="grey lighten-3"
            elevation="0"
            dense
        >

          <v-toolbar-title>Read File Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              icon
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="justify-center">

          <v-row justify="center" align="center">
            <v-col cols="5">
              <v-file-input
                  v-model="currentFile"
                  show-size
                  counter
                  label="Pilih File Excel"
                  accept=".xls,.xlsx"
                  @change="selectFile"
              ></v-file-input>
            </v-col>

            <v-col cols="2" class="pl-1">
              <v-autocomplete
                  v-model="fareaBeanSelected"
                  :items="itemsFArea"
                  item-value="id"
                  item-text="description"
                  dense
                  chips
                  deletable-chips
                  color="blue-grey lighten-2"
                  label="Kab/Kota"
                  hint="Kosong, maka akan disesuikan dengan kab/kota masing2"
                  persistent-hint
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" class="pl-2">
              <v-btn color="success" small @click="startUpload" :disabled="!currentFile  || sudahUpload===true">
                Upload
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>


          </v-row>

          <v-row v-if="currentFile">
            <v-col cols="12" sm="12" md="12">

              <v-data-table
                  :headers="headers"
                  :items="itemsFSubAreaComputed"
                  item-key="name"
                  class="elevation-1"
                  height="400"
                  calculate-widths
                  hide-default-footer
                  disable-pagination
              >
                <template v-slot:[`item.number`]="{ item }" >
                  <span><v-icon small color="green" v-if="item.statusUpload===1">mdi-check-circle</v-icon></span>
                  <span><v-icon small color="red" v-if="item.statusUpload===2">mdi-close</v-icon></span>
                  {{ item.number }}
                </template>

              </v-data-table>
            </v-col>

          </v-row>

          <div v-if="progress">
            <div>
              <v-progress-linear
                  v-model="progress"
                  color="light-blue"
                  height="25"
                  reactive
              >
                <strong>{{ progress }} %</strong>
              </v-progress-linear>
            </div>
          </div>

          <v-row v-if="previewImage" justify="center">
            <v-img class="ma-2" :src="previewImage" alt="" max-width="300px" max-height="300px"></v-img>
          </v-row>

        </v-card-text>

      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>


    </v-dialog>

    <v-dialog
        v-model="dialogProgres"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please Wait...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import FSubArea from "../../../models/f-sub-area";
import FSubAreaService from "../../../services/apiservices/f-sub-area-service"

export default {
  name: "ImportExcel",
  props:{
    parentId: Number,
    itemsFArea: []
  },
  components:{
  },
  data() {
    return {
      sudahUpload: false,

      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      currentFile: undefined,
      previewImage: undefined,

      fareaBeanSelected: '',
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

      progress: 0,
      message: "",
      description: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Nama Kecamatan', value: 'description' },
        { text: 'Kab/Kota', value: 'city' },
        { text: 'Lat', value: 'lat' },
        { text: 'Lon', value: 'lon' },
      ],
      itemsFSubArea:[],
    };
  },
  computed: {
    itemsFSubAreaComputed(){
      return this.itemsFSubArea
    }
  },

  methods: {

    selectFile(file) {
      this.currentFile = file;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";

      let rowKe =0
      readXlsxFile(this.currentFile).then( (rows) =>{
        rows.forEach( item => {
          if (rowKe >0){
            // console.log(JSON.stringify(item[1]))
            // console.log(JSON.stringify(item[1]))

            let fFSubAreaBean = new FSubArea()
            fFSubAreaBean.number = rowKe+1


            fFSubAreaBean.statusUpload =0
            fFSubAreaBean.description = item[0]!==null?item[0]:''
            fFSubAreaBean.city = item[1]!==null?item[1]:''

            fFSubAreaBean.lat = item[2]!==null?Number(item[2]):0.0
            fFSubAreaBean.lon = item[3]!==null?Number(item[3]):0.0

            this.itemsFSubArea.push(fFSubAreaBean)

          }

          rowKe++
        })
      })

    },

    startUpload() {
      if (!this.currentFile) {
        this.message = "Please select an Image!";
        return;
      }

      // if (
      //     this.fsubAreaTypeBeanSelected ===undefined || this.fsubAreaTypeBeanSelected===''
      // ) {
      //   this.message = "Tipe Infrastruktur Belum Dipilih!";
      //   return;
      // }

      // console.log(this.itemsFArea.length)


      let items = []
      this.itemsFSubArea.forEach( item =>{
        let newItem = item
        newItem.id = 0

        if (this.fareaBeanSelected !==''){
          newItem.fareaBean = this.fareaBeanSelected

        }else{
          const parent = this.itemsFArea.filter(x =>x.description.toUpperCase().includes(newItem.city.toUpperCase() ) )
          try{
            newItem.fareaBean = parent[0].id
          }catch (e) {
            e.toString()
          }

        }

        if (newItem.fareaBean>0){
          // console.log(newItem.fareaBean)
          items.push(newItem)
        }

      })


      FSubAreaService.createFSubAreaMultiple(items).then(
          response =>{

            // console.log('hello')
            // console.log(JSON.stringify(response.data))

            this.itemsFSubArea = []

            response.data.forEach( itemResponse => {
              const newItem = Object.assign({}, itemResponse)
              try {
                this.$nextTick(() => {
                  newItem.statusUpload = 1
                  this.itemsFSubArea.push(newItem)
                })
              }catch (e) {
                newItem.statusUpload = 2
                this.itemsFSubArea.push(newItem)
                console.log(e)
              }

            })

            this.sudahUpload = true
          },
          error =>{
            console.log(error.data)
          }
      )
      

    },

    showDialog(){
      this.dialogShow = true
      this.currentFile = undefined
      this.previewImage = undefined
      this.progress =0
      this.message = ""

      this.sudahUpload = false

      this.itemsFSubArea = []

    },
    closeDialog(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }
      if (this.sudahUpload) {
        this.$emit('importExcelCompleted', "OKE")
      }

    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },


  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
