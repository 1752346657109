<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabRegionClick">
        PROPINSI
      </v-tab>
      <v-tab class="justify-start" @click="tabAreaClick">
        KABUPATEN
      </v-tab>
      <v-tab class="justify-start" @click="tabSubAreaClick">
        KECAMATAN
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FRegionTable ref="refTabRegionClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FAreaTable ref="refTabAreaClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FSubAreaTable ref="refTabSubAreaClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>

  </v-card>
</template>

<script>

import FRegionTable from "../../components/wilayah/region/FRegionTable";
import FAreaTable from "../../components/wilayah/region/FAreaTable";
import FSubAreaTable from "../../components/wilayah/region/FSubAreaTable";

export default {
  components: { FRegionTable,  FAreaTable, FSubAreaTable  },
  data() {
    return {
      firstTabTitle: 'WILAYAH',
      firstTabIcon: 'mdi-map'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabRegionClick(){
      try {
        this.$refs.refTabRegionClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabAreaClick(){
      try {
        this.$refs.refTabAreaClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabSubAreaClick(){
      try {
        this.$refs.refTabSubAreaClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },



  }
}
</script>

<style scoped>
</style>